<template>
  <div class="div">
    <heads />
    <div class="deyails-div">
      <div class="deyails-return">
        <router-link :to="{name: 'notice'}"
          class="deyails-return-span">
          ﹤返回列表</router-link>
      </div>
      <div class="deyails-article">{{title}}</div>
      <div class="deyails-subTitle">{{subTitle}}</div>
      <div class="deyails-time">{{time}}</div>
      <div id="deyails"
        class="deyails-content vp" />
      <span v-if="files"
        style="margin-bottom:16px;display:block">附件:</span>
      <div v-for="item in files"
        :key="item.id">
        <a :href="item.url"
          :download="`${item.name}.${item.ext}`"
          target="downloadiframe"><span style="line-height:20px;color:rgb(2, 2, 30);cursor:pointer;">{{item.name}}.{{item.ext}}</span></a>
      </div>
      <iframe id="downloadiframe"
        style="display:none;" />

    </div>
    <foots class="foots" />
  </div>
</template>
<script>
import _ from 'lodash';
import dayjs from 'dayjs';
import { articleyGet } from './api.js';
import heads from './common/head';
import foots from './common/foot';

export default {
  components: { heads, foots },
  data() {
    return {
      id: this.$route.query.id,
      title: '',
      time: '',
      subTitle: '',
      files: [],
    };
  },
  watch: {},
  created() {
    this.getDetails();
  },
  methods: {
    getDetails() {
      articleyGet({ id: this.id }).then(({ data, err }) => {
        if (!err) {
          const { data: datas = [] } = data;
          const temp = _.head(datas) || {};
          this.title = temp.title;
          this.subTitle = temp.subTitle;
          // this.content = datas[0].content;
          const content = document.querySelector('#deyails');
          content.innerHTML = temp.content || '暂无内容';
          this.time = temp.createTime ? dayjs(temp.createTime).format('YYYY-MM-DD') : '';
          this.files = temp.files;
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.div {
  background: rgba(250, 250, 250, 1);
}
.deyails-div {
  width: 1200px;
  min-height: 650px;
  margin: 0 auto;
  padding-top: 203px;
  .deyails-article {
    text-align: center;
    display: inline-block;
    width: 1200px;
    font-size: 22px;
    color: rgba(38, 38, 38, 1);
    margin: 21px 0 0 0;
  }
  .deyails-subTitle {
    text-align: center;
    width: 1200px;
    display: inline-block;
    font-size: 22px;
    color: rgba(38, 38, 38, 1);
    margin: 21px 0 0 0;
  }
  .deyails-time {
    width: 1200px;
    display: inline-block;
    font-size: 16px;
    color: rgba(89, 89, 89, 1);
    margin: 21px 0 0 0;
  }
  .deyails-content {
    width: 1200px;
    font-size: 16px;
    color: rgba(89, 89, 89, 1);
    margin: 21px 0 0 0;
    line-height: 30px;
  }
  .deyails-return {
    // margin-left: 56px;
    .deyails-return-span {
      font-size: 14px;
      color: rgba(24, 144, 255, 1);
    }
  }
}
.foots {
  margin-top: 50px;
}
</style>
