var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "div" },
    [
      _c("heads"),
      _c(
        "div",
        { staticClass: "deyails-div" },
        [
          _c(
            "div",
            { staticClass: "deyails-return" },
            [
              _c(
                "router-link",
                {
                  staticClass: "deyails-return-span",
                  attrs: { to: { name: "notice" } }
                },
                [_vm._v("\n        ﹤返回列表")]
              )
            ],
            1
          ),
          _c("div", { staticClass: "deyails-article" }, [
            _vm._v(_vm._s(_vm.title))
          ]),
          _c("div", { staticClass: "deyails-subTitle" }, [
            _vm._v(_vm._s(_vm.subTitle))
          ]),
          _c("div", { staticClass: "deyails-time" }, [
            _vm._v(_vm._s(_vm.time))
          ]),
          _c("div", {
            staticClass: "deyails-content vp",
            attrs: { id: "deyails" }
          }),
          _vm.files
            ? _c(
                "span",
                { staticStyle: { "margin-bottom": "16px", display: "block" } },
                [_vm._v("附件:")]
              )
            : _vm._e(),
          _vm._l(_vm.files, function(item) {
            return _c("div", { key: item.id }, [
              _c(
                "a",
                {
                  attrs: {
                    href: item.url,
                    download: item.name + "." + item.ext,
                    target: "downloadiframe"
                  }
                },
                [
                  _c(
                    "span",
                    {
                      staticStyle: {
                        "line-height": "20px",
                        color: "rgb(2, 2, 30)",
                        cursor: "pointer"
                      }
                    },
                    [_vm._v(_vm._s(item.name) + "." + _vm._s(item.ext))]
                  )
                ]
              )
            ])
          }),
          _c("iframe", {
            staticStyle: { display: "none" },
            attrs: { id: "downloadiframe" }
          })
        ],
        2
      ),
      _c("foots", { staticClass: "foots" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }