import { render, staticRenderFns } from "./articleDetails.vue?vue&type=template&id=714fe839&scoped=true&"
import script from "./articleDetails.vue?vue&type=script&lang=js&"
export * from "./articleDetails.vue?vue&type=script&lang=js&"
import style0 from "./articleDetails.vue?vue&type=style&index=0&id=714fe839&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "714fe839",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/data2/code/repositories/apps/site/dev/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!module.hot.data) {
      api.createRecord('714fe839', component.options)
    } else {
      api.reload('714fe839', component.options)
    }
    module.hot.accept("./articleDetails.vue?vue&type=template&id=714fe839&scoped=true&", function () {
      api.rerender('714fe839', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/main/articleDetails.vue"
export default component.exports